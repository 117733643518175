<template>
    <div class="container estadisticas tablesync mt-4">
        <card type="profile default">
            <template slot="header">
                <h3>{{$t('tablesync.title')}}</h3>
                <h4>{{$t('tablesync.subtitle')}}</h4>
            </template>
            <v-client-table :columns="columns1" v-model="data1"  :options="options1">
                <router-link slot="uri" slot-scope="props" :to="$i18nRoute({name: 'Sync Redes', params: { id: props.row.Id }})"  class="nav-link" >
                    <button class="btn btn-bextsocial">{{$t('tablesync.button')}}</button>
                </router-link>
            </v-client-table>
        </card>
    </div>
</template>
<script>

import Card from "@/components/card/Card.component.vue";
import { mapState, mapMutations } from "vuex";
import BarCircle from "@/components/barCircle/BarCircle.component.vue";
import VueDataTable from '@/components/datatables/VueDataTable.component'
export default {
    name: 'TableSync',
    components: {
    Card,
    BarCircle,
    VueDataTable
  },
  data() {
    return {
      // COLUMNS OF TABLE ON BD
      columns1: ['Id', 'Descripcion', 'Fecha_sincronizacion', 'uri'],
      data1: [],
      options1: {
        headings: {
          Id: this.$t('tablesync.item1'),
          Descripcion: this.$t('tablesync.item2'),
          Fecha_sincronizacion: this.$t('tablesync.item3'),
          see: 'View Record'
        },
        editableColumns:['description'],
        sortable: ['Id', 'Fecha_sincronizacion'],
        filterabl: ['id', 'date', 'description'],
      }
    };
  },
  computed: {
    ...mapState(['enhance', 'social']),
  },
  async created(){
    this.Carga()
    try {
      const data =  this.social.filter(item => item.view == true)
      const id_empresa =  data.shift().Id_Empresa;
      const sync_data = await this.axios.post(`/bextsocial/syncaccounts/getsyncinfo`, {id_empresa: id_empresa});
      if(sync_data) {
        let arr = []
        sync_data.data.sort(function(x, y) {
          return  x.Id - y.Id;
        });
        arr.push(sync_data.data.slice(-1)[0])
        this.data1 = arr ;
        this.Carga()
      }
    } catch (error) {
      console.log(error);
      this.Carga()
    }
    
  },
  methods:{
    ...mapMutations(["Carga"]),
  },
  mounted(){
    
    //todo: Es como el windows-onload
  }
}
</script>